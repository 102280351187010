/**
 * Base styles
 */

html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

@media (min-width: 2000px) {
  .page-border {
    border-inline: solid 1px #fff;
  }
}

/**
 * Pagination
 */

.pagination {
  display: flex;
  list-style: none;
  padding: 1em;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* ~768px (md breakpoint) */
@media (min-width: 48em) {
  .pagination {
    padding: 2rem;
    /* Offset the margin-top on li so pagination is still centered */
    padding-top: 1.5rem;
  }
}

/* If the pagination wraps */
.pagination li {
  margin-top: 0.5em;
}

.pagination__link {
  padding-inline: 13px;
}

.pagination__link:hover {
  text-decoration: underline;
}

.pagination__link:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px #264a42, 0 0 2px 6px #d3dad8;
}

.pagination__link--number {
  border-right: solid 1px #fff;
}

.pagination__link--prev {
  padding-left: 0;
  border-right: solid 1px #fff;
}

.pagination__link--current {
  color: #727171;
  font-weight: 600;
}

.pagination .disabled {
  color: #727171;
  pointer-events: none;
  text-decoration: none;
}

/**
 * Rounded corners: cards
 */

.rounded-corners {
  position: relative;
}

.rounded-corners::after {
  content: "";
  display: none;
  position: absolute;
  width: 25px;
  height: 11px;
}

.rounded-corners:first-child::after {
  right: -13px;
  top: -1px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 11'%3E%3Cg fill='%23FFF' fill-rule='evenodd' stroke='%23FFF'%3E%3Cpath d='M12.5 0v11C12.5 4.925 6.904 0 0 0h12.5ZM12.5 0v11c0-6.075 5.596-11 12.5-11H12.5Z'/%3E%3C/g%3E%3C/svg%3E");
}

.rounded-corners:last-child::after {
  left: -13px;
  bottom: -1px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 11'%3E%3Cg fill='%23FFF' fill-rule='evenodd' stroke='%23FFF'%3E%3Cpath d='M12.5 11V0c0 6.075-5.596 11-12.5 11h12.5ZM12.5 11V0c0 6.075 5.596 11 12.5 11H12.5Z'/%3E%3C/g%3E%3C/svg%3E");
}

.rounded-corners.rounded-corners--bottom:first-child::after {
  display: none;
}

.rounded-corners.rounded-corners--top:last-child::after {
  display: none;
}

.rounded-corners.rounded-corners--no-bp::after {
  display: block;
}

.rounded-corners.rounded-corners--mobile-only::after {
  display: block;
}

/* ~768px (md breakpoint) */
@media (min-width: 48em) {
  .rounded-corners::after {
    display: block;
  }

  .rounded-corners.rounded-corners--mobile-only::after {
    display: none;
  }

  .rounded-corners--bp-xl.rounded-corners::after {
    display: none;
  }
}

/* ~1280px (xl breakpoint) */
@media (min-width: 80em) {
  .rounded-corners--bp-xl.rounded-corners::after {
    display: block;
  }
}

/**
 * Rounded corners: search right column
 */

.search-right-col {
  position: relative;
}

.search-right-col::before,
.search-right-col::after {
  content: "";
  display: none;
  position: absolute;
  width: 25px;
  height: 11px;
  z-index: 1;
}

.search-right-col::before {
  left: -13px;
  top: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 11'%3E%3Cg fill='%23FFF' fill-rule='evenodd' stroke='%23FFF'%3E%3Cpath d='M12.5 0v11C12.5 4.925 6.904 0 0 0h12.5ZM12.5 0v11c0-6.075 5.596-11 12.5-11H12.5Z'/%3E%3C/g%3E%3C/svg%3E");
}

.search-right-col::after {
  left: -13px;
  bottom: -1px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 11'%3E%3Cg fill='%23FFF' fill-rule='evenodd' stroke='%23FFF'%3E%3Cpath d='M12.5 11V0c0 6.075-5.596 11-12.5 11h12.5ZM12.5 11V0c0 6.075 5.596 11 12.5 11H12.5Z'/%3E%3C/g%3E%3C/svg%3E");
}

/* ~992px (lg breakpoint) */
@media (min-width: 62em) {
  .search-right-col::before,
  .search-right-col::after {
    display: block;
  }
}

/**
 * Arrow link
 */
 .arrow-link::after {
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  /* Add alt text to arrow content to stop SRs from reading them out */
  /* Fallback for unsupported browsers */
  content: "→";
  content: "→" / "";
  display: inline-block;
  margin-left: 4px;
}

/* External */
.arrow-link--external::after {
  /* Add alt text to arrow content to stop SRs from reading them out */
  /* Fallback for unsupported browsers */
  content: "↗";
  content: "↗" / "";
}

/* Up/down arrows */
.arrow-link--up::after {
  /* Add alt text to arrow content to stop SRs from reading them out */
  /* Fallback for unsupported browsers */
  content: "↑";
  content: "↑" / "";
}

.arrow-link--down::after {
  /* Add alt text to arrow content to stop SRs from reading them out */
  /* Fallback for unsupported browsers */
  content: "↓";
  content: "↓" / "";
}

/* ::before pseudo elements */
.arrow-link--before::after {
  content: "";
}

.arrow-link--before::before {
  /* Add alt text to arrow content to stop SRs from reading them out */
  /* Fallback for unsupported browsers */
  content: "→";
  content: "→" / "";
  margin-inline: 4px;
  display: inline-block;
}

/* Back arrow */
.arrow-link--back::after {
  content: "";
}

.arrow-link--back::before {
  /* Add alt text to arrow content to stop SRs from reading them out */
  /* Fallback for unsupported browsers */
  content: "←";
  content: "←" / "";
  margin-inline: 4px;
  display: inline-block;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/**
 * General overrides
 */

/* Override for OSD applying display: inline-block to buttons */
.osd-button {
  display: flex !important;
}

/* Override for Chakra UI setting line-height on radio labels */
.chakra-radio__label {
  line-height: 1.25rem !important;
}
